module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Balenciaga Fall22","short_name":"Balenciaga Fall22","start_url":"/","background_color":"#FFFFFF","theme_color":"#FFFFFF","display":"standalone","orientation":"portrait-primary","lang":"fr","crossOrigin":"use-credentials","icon":"src/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"9a837b279cd5b132ef21c04e207c4297"},
    },{
      plugin: require('../node_modules/gatsby-plugin-jss-provider/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/configuration/jss","minify":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/buddy/balenciaga-fall22-frontend/src/intl/messages","languages":["en"],"defaultLanguage":"en","redirect":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5L9NGRZ","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
